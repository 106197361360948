import React from 'react'

import Layout from '../components/layout'
import Seo from '../components/seo'

const NotFoundPage = () => (
  <Layout>
    <Seo title='404: Not found' />
    <h1>NOT FOUND</h1>
  </Layout>
)

export default NotFoundPage
